$(function () {
    $.ajaxSetup({
        headers: {'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')}
    });
});

/*! SmartAdmin - v1.5.2 - 2015-04-23 */
function runAllForms() {
    $.fn.datepicker && $(".datepicker").each(function () {
        var a = $(this),
            b = a.attr("data-dateformat") || "dd.mm.yy";
        a.datepicker({
            "dateFormat": b,
            "prevText": '<i class="fa fa-chevron-left"></i>',
            "nextText": '<i class="fa fa-chevron-right"></i>'
        }), a = null
    }), $("button[data-loading-text]").on("click", function () {
        var a = $(this);
        a.button("loading"), setTimeout(function () {
            a.button("reset"), a = null
        }, 3e3)
    })
}
var breakpointDefinition = {
    tablet: 1024,
    phone: 480
};

function setup_widgets_desktop() {
    $.fn.jarvisWidgets && enableJarvisWidgets && $("#widget-grid").jarvisWidgets({
        "grid": "article",
        "widgets": ".jarviswidget",
        "localStorage": localStorageJarvisWidgets,
        "deleteSettingsKey": "#deletesettingskey-options",
        "settingsKeyLabel": "Reset settings?",
        "deletePositionKey": "#deletepositionkey-options",
        "positionKeyLabel": "Reset position?",
        "sortable": sortableJarvisWidgets,
        "buttonsHidden": !1,
        "toggleButton": true,
        "toggleClass": "fa fa-minus | fa fa-plus",
        "toggleSpeed": 200,
        "onToggle": function () {
        },
        "deleteButton": false,
        "deleteMsg": "Warning: This action cannot be undone!",
        "deleteClass": "fa fa-times",
        "deleteSpeed": 200,
        "onDelete": function () {
        },
        "editButton": false,
        "editPlaceholder": ".jarviswidget-editbox",
        "editClass": "fa fa-cog | fa fa-save",
        "editSpeed": 200,
        "onEdit": function () {
        },
        "colorButton": false,
        "fullscreenButton": !0,
        fullscreenButton: false,
        "fullscreenClass": "fa fa-expand | fa fa-compress",
        "fullscreenDiff": 3,
        "onFullscreen": function () {
        },
        "customButton": !1,
        customButton: false,
        "customClass": "folder-10 | next-10",
        "customStart": function () {
            alert("Hello you, this is a custom button...")
        },
        "customEnd": function () {
            alert("bye, till next time...")
        },
        "buttonOrder": "%refresh% %custom% %edit% %toggle% %fullscreen% %delete%",
        "opacity": 1,
        "dragHandle": "> header",
        "placeholderClass": "jarviswidget-placeholder",
        "indicator": !0,
        "indicatorTime": 600,
        "ajax": !0,
        "timestampPlaceholder": ".jarviswidget-timestamp",
        "timestampFormat": "Last update: %m%/%d%/%y% %h%:%i%:%s%",
        "refreshButton": !0,
        "refreshButtonClass": "fa fa-refresh",
        "labelError": "Sorry but there was a error:",
        "labelUpdated": "Last Update:",
        "labelRefresh": "Refresh",
        "labelDelete": "Delete widget:",
        "afterLoad": function () {
        },
        "rtl": !1,
        "onChange": function () {
        },
        "onSave": function () {
        },
        "ajaxnav": $.navAsAjax
    })
}

function setup_widgets_mobile() {
    enableMobileWidgets && enableJarvisWidgets && setup_widgets_desktop()
}

function loadScript(a, b) {
    if (jsArray[a]) b && (debugState && root.root.console.log("This script was already loaded %c: " + a, debugStyle_warning), b());
    else {
        jsArray[a] = !0;
        var c = document.getElementsByTagName("body")[0],
            d = document.createElement("script");
        d.type = "text/javascript", d.src = a, d.onload = b, c.appendChild(d)
    }
}

function checkURL() {
    var a = location.href.split("#").splice(1).join("#");
    if (!a) try {
        var b = window.document.URL;
        b && b.indexOf("#", 0) > 0 && b.indexOf("#", 0) < b.length + 1 && (a = b.substring(b.indexOf("#", 0) + 1))
    } catch (c) {
    }
    if (container = $("#content"), a) {
        $("nav li.active").removeClass("active"), $('nav li:has(a[href="' + a + '"])').addClass("active");
        var d = $('nav a[href="' + a + '"]').attr("title");
        document.title = d || document.title, debugState && root.console.log("Page title: %c " + document.title, debugStyle_green), loadURL(a + location.search, container)
    } else {
        var e = $('nav > ul > li:first-child > a[href!="#"]');
        window.location.hash = e.attr("href"), e = null
    }
}

function loadURL(a, b) {
    debugState && root.root.console.log("Loading URL: %c" + a, debugStyle), $.ajax({
        "type": "GET",
        "url": a,
        "dataType": "html",
        "cache": !0,
        "beforeSend": function () {
            if ($.navAsAjax && $(".google_maps")[0] && b[0] == $("#content")[0]) {
                var a = $(".google_maps"),
                    c = 0;
                a.each(function () {
                    c++;
                    var b = document.getElementById(this.id);
                    c == a.length + 1 || (b && b.parentNode.removeChild(b), debugState && root.console.log("Destroying maps.........%c" + this.id, debugStyle_warning))
                }), debugState && root.console.log("\u2714 Google map instances nuked!!!")
            }
            if ($.navAsAjax && $(".dataTables_wrapper")[0] && b[0] == $("#content")[0]) {
                var d = $.fn.dataTable.fnTables(!0);
                $(d).each(function () {
                    0 != $(this).find(".details-control").length ? ($(this).find("*").addBack().off().remove(), $(this).dataTable().fnDestroy()) : $(this).dataTable().fnDestroy()
                }), debugState && root.console.log("\u2714 Datatable instances nuked!!!")
            }
            if ($.navAsAjax && $.intervalArr.length > 0 && b[0] == $("#content")[0] && enableJarvisWidgets) {
                for (; $.intervalArr.length > 0;) clearInterval($.intervalArr.pop());
                debugState && root.console.log("\u2714 All JarvisWidget intervals cleared")
            }
            if ($.navAsAjax && b[0] == $("#content")[0] && enableJarvisWidgets && $("#widget-grid")[0] && ($("#widget-grid").jarvisWidgets("destroy"), debugState && root.console.log("\u2714 JarvisWidgets destroyed")), $.navAsAjax && b[0] == $("#content")[0]) {
                if ("function" == typeof pagedestroy) try {
                    pagedestroy(), debugState && root.console.log("\u2714 Pagedestroy()")
                } catch (e) {
                    pagedestroy = void 0, debugState && root.console.log("! Pagedestroy() Catch Error")
                }
                $.fn.sparkline && $("#content .sparkline")[0] && ($("#content .sparkline").sparkline("destroy"), debugState && root.console.log("\u2714 Sparkline Charts destroyed!")), $.fn.easyPieChart && $("#content .easy-pie-chart")[0] && ($("#content .easy-pie-chart").easyPieChart("destroy"), debugState && root.console.log("\u2714 EasyPieChart Charts destroyed!")), $.fn.select2 && $("#content select.select2")[0] && ($("#content select.select2").select2("destroy"), debugState && root.console.log("\u2714 Select2 destroyed!")), $.fn.mask && $("#content [data-mask]")[0] && ($("#content [data-mask]").unmask(), debugState && root.console.log("\u2714 Input Mask destroyed!")), $.fn.datepicker && $("#content .datepicker")[0] && ($("#content .datepicker").off(), $("#content .datepicker").remove(), debugState && root.console.log("\u2714 Datepicker destroyed!")), $.fn.slider && $("#content .slider")[0] && ($("#content .slider").off(), $("#content .slider").remove(), debugState && root.console.log("\u2714 Bootstrap Slider destroyed!"))
            }
            pagefunction = null, b.removeData().html(""), b.html('<h1 class="ajax-loading-animation"><i class="fa fa-cog fa-spin"></i> Loading...</h1>'), b[0] == $("#content")[0] && ($("body").find("> *").filter(":not(" + ignore_key_elms + ")").empty().remove(), drawBreadCrumb(), $("html").animate({
                "scrollTop": 0
            }, "fast"))
        },
        "success": function (a) {
            b.css({
                "opacity": "0.0"
            }).html(a).delay(50).animate({
                "opacity": "1.0"
            }, 300), a = null, b = null
        },
        "error": function (c, d, e) {
            b.html('<h4 class="ajax-loading-error"><i class="fa fa-warning txt-color-orangeDark"></i> Error requesting <span class="txt-color-red">' + a + "</span>: " + c.status + ' <span style="text-transform: capitalize;">' + e + "</span></h4>")
        },
        "async": !0
    })
}

function drawBreadCrumb(a) {
    var b = $("nav li.active > a"),
        c = b.length;
    bread_crumb.empty(), bread_crumb.append($("<li>Home</li>")), b.each(function () {
        bread_crumb.append($("<li></li>").html($.trim($(this).clone().children(".badge").remove().end().text()))), --c || (document.title = bread_crumb.find("li:last-child").text())
    }), void 0 != a && $.each(a, function (a, b) {
        bread_crumb.append($("<li></li>").html(b)), document.title = bread_crumb.find("li:last-child").text()
    })
}

function pageSetUp() {
    "desktop" === thisDevice ? ($("[rel=tooltip], [data-rel=tooltip]").tooltip(), $("[rel=popover], [data-rel=popover]").popover(), $("[rel=popover-hover], [data-rel=popover-hover]").popover({
            "trigger": "hover"
        }), setup_widgets_desktop(), runAllForms()) : ($("[rel=popover], [data-rel=popover]").popover(), $("[rel=popover-hover], [data-rel=popover-hover]").popover({
            "trigger": "hover"
        }), setup_widgets_mobile(), runAllForms());
    $("[data-toggle=popover]").popover({

        html: true,
        content: function () {
            var content = $(this).attr("data-popover-content");
            return $(content).children(".popover-body").html();
        }
    });
}

function getParam(a) {
    a = a.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var b = "[\\?&]" + a + "=([^&#]*)",
        c = new RegExp(b),
        d = c.exec(window.location.href);
    return null == d ? "" : d[1]
}
$.intervalArr = [];
$.root_ = $('body');

var calc_navbar_height = function () {
        var a = null;
        return $("#header").length && (a = $("#header").height()), null === a && (a = $('<div id="header"></div>').height()), null === a ? 49 : a
    }, navbar_height = calc_navbar_height,
    shortcut_dropdown = $("#shortcut"),
    bread_crumb = $("#ribbon ol.breadcrumb"),
    topmenu = !1,
    thisDevice = null,
    ismobile = /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()),
    jsArray = {}, initApp = function (a) {
        return a.addDeviceType = function () {
            return ismobile ? ($.root_.addClass("mobile-detected"), thisDevice = "mobile", fastClick ? ($.root_.addClass("needsclick"), FastClick.attach(document.body), !1) : void 0) : ($.root_.addClass("desktop-detected"), thisDevice = "desktop", !1)
        }, a.menuPos = function () {
            ($.root_.hasClass("menu-on-top") || "top" == localStorage.getItem("sm-setmenu")) && (topmenu = !0, $.root_.addClass("menu-on-top"))
        }, a.SmartActions = function () {
            var a = {
                "userLogout": function (a) {
                    function b() {
                        window.location = a.attr("href")
                    }

                    $.SmartMessageBox({
                        "title": "<i class='fa fa-sign-out txt-color-orangeDark'></i> Logout <span class='txt-color-orangeDark'><strong>" + $("#show-shortcut").text() + "</strong></span> ?",
                        "content": a.data("logout-msg") || "You can improve your security further after logging out by closing this opened browser",
                        "buttons": "[No][Yes]"
                    }, function (a) {
                        "Yes" == a && ($.root_.addClass("animated fadeOutUp"), setTimeout(b, 1e3))
                    })
                },
                "resetWidgets": function (a) {
                    $.SmartMessageBox({
                        "title": "<i class='fa fa-refresh' style='color:green'></i> Clear Local Storage",
                        "content": a.data("reset-msg") || "Would you like to RESET all your saved widgets and clear LocalStorage?1",
                        "buttons": "[No][Yes]"
                    }, function (a) {
                        "Yes" == a && localStorage && (localStorage.clear(), location.reload())
                    })
                },
                "launchFullscreen": function (a) {
                    $.root_.hasClass("full-screen") ? ($.root_.removeClass("full-screen"), document.exitFullscreen ? document.exitFullscreen() : document.mozCancelFullScreen ? document.mozCancelFullScreen() : document.webkitExitFullscreen && document.webkitExitFullscreen()) : ($.root_.addClass("full-screen"), a.requestFullscreen ? a.requestFullscreen() : a.mozRequestFullScreen ? a.mozRequestFullScreen() : a.webkitRequestFullscreen ? a.webkitRequestFullscreen() : a.msRequestFullscreen && a.msRequestFullscreen())
                },
                "minifyMenu": function (a) {
                    $.root_.hasClass("menu-on-top") || ($.root_.toggleClass("minified"), $.root_.removeClass("hidden-menu"), $("html").removeClass("hidden-menu-mobile-lock"), a.effect("highlight", {}, 500))
                },
                "toggleMenu": function () {
                    $.root_.hasClass("menu-on-top") ? $.root_.hasClass("menu-on-top") && $(window).width() < 979 && ($("html").toggleClass("hidden-menu-mobile-lock"), $.root_.toggleClass("hidden-menu"), $.root_.removeClass("minified")) : ($("html").toggleClass("hidden-menu-mobile-lock"), $.root_.toggleClass("hidden-menu"), $.root_.removeClass("minified"))
                },
                "toggleShortcut": function () {
                    function a() {
                        shortcut_dropdown.animate({
                            "height": "hide"
                        }, 300, "easeOutCirc"), $.root_.removeClass("shortcut-on")
                    }

                    function b() {
                        shortcut_dropdown.animate({
                            "height": "show"
                        }, 200, "easeOutCirc"), $.root_.addClass("shortcut-on")
                    }

                    shortcut_dropdown.is(":visible") ? a() : b(), shortcut_dropdown.find("a").click(function (b) {
                        b.preventDefault(), window.location = $(this).attr("href"), setTimeout(a, 300)
                    }), $(document).mouseup(function (b) {
                        shortcut_dropdown.is(b.target) || 0 !== shortcut_dropdown.has(b.target).length || a()
                    })
                }
            };
            $.root_.on("click", '[data-action="userLogout"]', function (b) {
                var c = $(this);
                a.userLogout(c), b.preventDefault(), c = null
            }), $.root_.on("click", '[data-action="resetWidgets"]', function (b) {
                var c = $(this);
                a.resetWidgets(c), b.preventDefault(), c = null
            }), $.root_.on("click", '[data-action="launchFullscreen"]', function (b) {
                a.launchFullscreen(document.documentElement), b.preventDefault()
            }), $.root_.on("click", '[data-action="minifyMenu"]', function (b) {
                var c = $(this);
                a.minifyMenu(c), b.preventDefault(), c = null
            }), $.root_.on("click", '[data-action="toggleMenu"]', function (b) {
                a.toggleMenu(), b.preventDefault()
            }), $.root_.on("click", '[data-action="toggleShortcut"]', function (b) {
                a.toggleShortcut(), b.preventDefault()
            })
        }, /*a.leftNav = function () {
         topmenu || $("nav ul").jarvismenu({
         "accordion": menu_accordion || !0,
         "speed": menu_speed || !0,
         "closedSign": '<em class="fa fa-plus-square-o"></em>',
         "openedSign": '<em class="fa fa-minus-square-o"></em>'
         })
         },*/ a.domReadyMisc = function () {
            $("[rel=tooltip]").length && $("[rel=tooltip]").tooltip(), $("#search-mobile").click(function () {
                $.root_.addClass("search-mobile")
            }), $("#cancel-search-js").click(function () {
                $.root_.removeClass("search-mobile")
            }), $("#activity").click(function (a) {
                var b = $(this);
                b.find(".badge").hasClass("bg-color-red") && (b.find(".badge").removeClassPrefix("bg-color-"), b.find(".badge").text("0")), b.next(".ajax-dropdown").is(":visible") ? (b.next(".ajax-dropdown").fadeOut(150), b.removeClass("active")) : (b.next(".ajax-dropdown").fadeIn(150), b.addClass("active"));
                var c = b.next(".ajax-dropdown").find(".btn-group > .active > input").attr("id");
                b = null, c = null, a.preventDefault()
            }), $('input[name="activity"]').change(function () {
                var a = $(this);
                url = a.attr("id"), container = $(".ajax-notifications"), loadURL(url, container), a = null
            }), $(document).mouseup(function (a) {
                $(".ajax-dropdown").is(a.target) || 0 !== $(".ajax-dropdown").has(a.target).length || ($(".ajax-dropdown").fadeOut(150), $(".ajax-dropdown").prev().removeClass("active"))
            }), $("button[data-btn-loading]").on("click", function () {
                var a = $(this);
                a.button("loading"), setTimeout(function () {
                    a.button("reset")
                }, 3e3)
            }), $this = $("#activity > .badge"), parseInt($this.text()) > 0 && ($this.addClass("bg-color-red bounceIn animated"), $this = null)
        }, a.mobileCheckActivation = function () {
            $(window).width() < 979 ? ($.root_.addClass("mobile-view-activated"), $.root_.removeClass("minified")) : $.root_.hasClass("mobile-view-activated") && $.root_.removeClass("mobile-view-activated"), debugState && console.log("mobileCheckActivation")
        }, a
    }({});
initApp.addDeviceType(), initApp.menuPos(), jQuery(document).ready(function () {
    initApp.SmartActions(), /*initApp.leftNav(), */initApp.domReadyMisc()
}),
    function (a, b, c) {
        function d() {
            e = b[h](function () {
                f.each(function () {
                    var b, c, d = a(this),
                        e = a.data(this, j);
                    try {
                        b = d.width()
                    } catch (f) {
                        b = d.width
                    }
                    try {
                        c = d.height()
                    } catch (f) {
                        c = d.height
                    }
                    (b !== e.w || c !== e.h) && d.trigger(i, [e.w = b, e.h = c])
                }), d()
            }, g[k])
        }

        var e, f = a([]),
            g = a.resize = a.extend(a.resize, {}),
            h = "setTimeout",
            i = "resize",
            j = i + "-special-event",
            k = "delay",
            l = "throttleWindow";
        g[k] = throttle_delay, g[l] = !0, a.event.special[i] = {
            "setup": function () {
                if (!g[l] && this[h]) return !1;
                var b = a(this);
                f = f.add(b);
                try {
                    a.data(this, j, {
                        "w": b.width(),
                        "h": b.height()
                    })
                } catch (c) {
                    a.data(this, j, {
                        "w": b.width,
                        "h": b.height
                    })
                }
                1 === f.length && d()
            },
            "teardown": function () {
                if (!g[l] && this[h]) return !1;
                var b = a(this);
                f = f.not(b), b.removeData(j), f.length || clearTimeout(e)
            },
            "add": function (b) {
                function d(b, d, f) {
                    var g = a(this),
                        h = a.data(this, j);
                    h.w = d !== c ? d : g.width(), h.h = f !== c ? f : g.height(), e.apply(this, arguments)
                }

                if (!g[l] && this[h]) return !1;
                var e;
                return a.isFunction(b) ? (e = b, d) : (e = b.handler, void(b.handler = d))
            }
        }
    }(jQuery, this), $("#main").resize(function () {
    initApp.mobileCheckActivation()
});
var ie = function () {
    for (var a, b = 3, c = document.createElement("div"), d = c.getElementsByTagName("i"); c.innerHTML = "<!--[if gt IE " + ++b + "]><i></i><![endif]-->", d[0];);
    return b > 4 ? b : a
}();
if ($.fn.extend({
        "jarvismenu": function (a) {
            var b = {
                    "accordion": "true",
                    "speed": 200,
                    "closedSign": "[+]",
                    "openedSign": "[-]"
                }, c = $.extend(b, a),
                d = $(this);
            d.find("li").each(function () {
                0 !== $(this).find("ul").size() && ($(this).find("a:first").append("<b class='collapse-sign'>" + c.closedSign + "</b>"), "#" == $(this).find("a:first").attr("href") && $(this).find("a:first").click(function () {
                    return !1
                }))
            }), d.find("li.active").each(function () {
                $(this).parents("ul").slideDown(c.speed), $(this).parents("ul").parent("li").find("b:first").html(c.openedSign), $(this).parents("ul").parent("li").addClass("open")
            }), d.find("li a").click(function () {
                0 !== $(this).parent().find("ul").size() && (c.accordion && ($(this).parent().find("ul").is(":visible") || (parents = $(this).parent().parents("ul"), visible = d.find("ul:visible"), visible.each(function (a) {
                    var b = !0;
                    parents.each(function (c) {
                        return parents[c] == visible[a] ? (b = !1, !1) : void 0
                    }), b && $(this).parent().find("ul") != visible[a] && $(visible[a]).slideUp(c.speed, function () {
                        $(this).parent("li").find("b:first").html(c.closedSign), $(this).parent("li").removeClass("open")
                    })
                }))), $(this).parent().find("ul:first").is(":visible") && !$(this).parent().find("ul:first").hasClass("active") ? $(this).parent().find("ul:first").slideUp(c.speed, function () {
                        $(this).parent("li").removeClass("open"), $(this).parent("li").find("b:first").delay(c.speed).html(c.closedSign)
                    }) : $(this).parent().find("ul:first").slideDown(c.speed, function () {
                        $(this).parent("li").addClass("open"), $(this).parent("li").find("b:first").delay(c.speed).html(c.openedSign)
                    }))
            })
        }
    }), jQuery.fn.doesExist = function () {
        return jQuery(this).length > 0
    }, $.navAsAjax || $(".google_maps")) {
    var gMapsLoaded = !1;
    window.gMapsCallback = function () {
        gMapsLoaded = !0, $(window).trigger("gMapsLoaded")
    }, window.loadGoogleMaps = function () {
        if (gMapsLoaded) return window.gMapsCallback();
        var a = document.createElement("script");
        a.setAttribute("type", "text/javascript"), a.setAttribute("src", "http://maps.google.com/maps/api/js?sensor=false&callback=gMapsCallback"), (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(a)
    }
}
$.navAsAjax && ($("nav").length && checkURL(), $(document).on("click", 'nav a[href!="#"]', function (a) {
    a.preventDefault();
    var b = $(a.currentTarget);
    b.parent().hasClass("active") || b.attr("target") || ($.root_.hasClass("mobile-view-activated") ? ($.root_.removeClass("hidden-menu"), $("html").removeClass("hidden-menu-mobile-lock"), window.setTimeout(function () {
            window.location.search ? window.location.href = window.location.href.replace(window.location.search, "").replace(window.location.hash, "") + "#" + b.attr("href") : window.location.hash = b.attr("href")
        }, 150)) : window.location.search ? window.location.href = window.location.href.replace(window.location.search, "").replace(window.location.hash, "") + "#" + b.attr("href") : window.location.hash = b.attr("href"))
}), $(document).on("click", 'nav a[target="_blank"]', function (a) {
    a.preventDefault();
    var b = $(a.currentTarget);
    window.open(b.attr("href"))
}), $(document).on("click", 'nav a[target="_top"]', function (a) {
    a.preventDefault();
    var b = $(a.currentTarget);
    window.location = b.attr("href")
}), $(document).on("click", 'nav a[href="#"]', function (a) {
    a.preventDefault()
}), $(window).on("hashchange", function () {
    checkURL()
})), $("body").on("click", function (a) {
    $('[rel="popover"], [data-rel="popover"]').each(function () {
        $(this).is(a.target) || 0 !== $(this).has(a.target).length || 0 !== $(".popover").has(a.target).length || $(this).popover("hide")
    })
}), $("body").on("hidden.bs.modal", ".modal", function () {
    $(this).removeData("bs.modal")
});


/* ~ END: HELPFUL FUNCTIONS */
pageSetUp();
/**
 * TREE
 */
$('.tree > ul').attr('role', 'tree').find('ul').attr('role', 'group');
$('.tree').find('li:has(ul)').addClass('parent_li').attr('role', 'treeitem').find(' > span').attr('title', 'Collapse this branch').on('click', function (e) {
    var children = $(this).parent('li.parent_li').find(' > ul > li');
    if (children.is(':visible')) {
        children.hide('fast');
        $(this).attr('title', 'Expand this branch').find(' > i').removeClass().addClass('fa fa-lg fa-plus-circle');
    } else {
        children.show('fast');
        $(this).attr('title', 'Collapse this branch').find(' > i').removeClass().addClass('fa fa-lg fa-minus-circle');
    }
    e.stopPropagation();
});
$('.tree_manual > ul').attr('role', 'tree').find('ul').attr('role', 'group');
$('.tree_manual').find('li:has(ul)').addClass('parent_li').attr('role', 'treeitem').find(' > span').attr('title', 'Collapse this branch').on('click', function (e) {
    var children = $(this).parent('li.parent_li').find(' > ul > li');
    if (children.is(':visible')) {
        children.hide('fast');
        $(this).attr('title', 'Expand this branch').find(' > i').removeClass().addClass('fa fa-lg fa-caret-right');
    } else {
        children.show('fast');
        $(this).attr('title', 'Collapse this branch').find(' > i').removeClass().addClass('fa fa-lg fa-caret-down');
    }
    e.stopPropagation();
});
$('.tree_manual').find('li:has(ul) li').each(function () {
    $(this).hide();
    $(this).parents('.tree_manual').find('i').removeClass().addClass('fa fa-lg fa-caret-right');
});
$('#tree_menu').click(function (e) {
    var target = $(e.target), action = target.data('action');
    var block = $(this).parent().find('.tree_manual > ul li.parent_li > ul > li');
    if (action === 'collapse-all') {
        block.hide('fast');
        block.find(' > i').removeClass().addClass('fa fa-lg fa-caret-right');
    }
    if (action === 'expand-all') {
        block.show('fast');
        block.find(' > i').removeClass().addClass('fa fa-lg fa-caret-down');
    }
});


var laravel = {
    initialize: function () {
        this.methodLinks = $('a[data-method]');

        this.registerEvents();
    },

    registerEvents: function () {
        this.methodLinks.on('click', this.handleMethod);
    },

    handleMethod: function (e) {
        var link = $(this);
        var httpMethod = link.data('method').toUpperCase();
        var form;
        // If the data-method attribute is not PUT or DELETE,
        // then we don't know what to do. Just ignore.
        if ($.inArray(httpMethod, ['PUT', 'DELETE', 'POST']) === -1) {
            return;
        }
        // Allow user to optionally provide data-confirm="Are you sure?"
        if (link.data('confirm')) {

            $.SmartMessageBox({
                //: "Confirmation ?",
                title: link.data('confirm'),
                buttons: '[No][Yes]'
            }, function (ButtonPressed) {
                if (ButtonPressed === "Yes") {
                    form = laravel.createForm(link);
                    form.submit();
                }
                if (ButtonPressed === "No") {
                    return false
                }

            });
        } else {
            form = laravel.createForm(link);
            form.submit();
        }
        e.preventDefault();
    },


    createForm: function (link) {
        var form =
            $('<form>', {
                'method': 'POST',
                'action': link.attr('href')
            });

        var token =
            $('<input>', {
                'type': 'hidden',
                'name': '_token',
                'value': $('meta[name=csrf_token]').attr('content')
            });

        var hiddenInput =
            $('<input>', {
                'name': '_method',
                'type': 'hidden',
                'value': link.data('method')
            });

        return form.append(token, hiddenInput)
            .appendTo('body');
    }
};
laravel.initialize();

if ($('.dataTable').length) {

    var responsiveHelper_dt_basic = undefined;

    $('.dataTable').dataTable({
        responsive: true,
        "pageLength": 25,
        "aaSorting": [],
        "columnDefs": [{
            "targets": 'sorting_disabled',
            "orderable": false
        }],
        "sDom": "<'dt-toolbar'<'col-xs-12 col-sm-6'f><'col-sm-6 col-xs-12 hidden-xs'l>r>" +
        "t" +
        "<'dt-toolbar-footer'<'col-sm-6 col-xs-12 hidden-xs'i><'col-xs-12 col-sm-6'p>>",
        "autoWidth": true,
        "preDrawCallback": function () {
            // Initialize the responsive datatables helper once.
            if (!responsiveHelper_dt_basic) {
                responsiveHelper_dt_basic = new ResponsiveDatatablesHelper($('#dt_basic'), breakpointDefinition);
            }
        },
        "rowCallback": function (nRow) {
            responsiveHelper_dt_basic.createExpandIcon(nRow);
        },
        "drawCallback": function (oSettings) {
            responsiveHelper_dt_basic.respond();
        }
    });
}

$(document).on('keyup keypress', 'form input[type="text"]', function (e) {
    if (e.which == 13) {
        e.preventDefault();
        return false;
    }
});


$('button[type="submit"]').click(function (e) {
    if ($(this).attr('autosave') == 1 || (form.length && form.valid() && !$(this).attr('data-action'))) {
        bufferModal()
    }
});
$('a[autosave]').click(function (e) {
    bufferModal()
});
$('.activity-dropdown').click(function () {
    $('.ajax-dropdown').toggle('up');
    $('.activity-dropdown i').toggle();

    url = '/ajax/activities';
    container = $(".notifications");
    loadURL(url, container)

});
$('.listpermissions').click(function () {

    $('.ajax-dropdown-permissions').toggle('up');
    url = '/ajax/permissions';
    container = $(".getPermissions");
    loadURL(url, container)
});


$('.modalManual').click(function (e) {
    e.preventDefault();
    var reseller_id = $(this).attr('data-reseller_id');
    var type = $(this).attr('data-type');

    $.ajax({
        url: "/ajax/modalmanual",
        data: {
            reseller_id: reseller_id,
            type: type
        },
        dataType: 'html',
        type: 'POST',
        success: function (data, statut) {
            $('.allmodal').html(data);
            $('#AllModal').modal();
        }
    });
});

$('.showShortcut').click(function () {
    $('.listShortcut').toggle('up');
});
function bufferModal() {
    setTimeout(pendingModal, 2000);
}

function bufferRedirection() {
    setTimeout(location.reload(), 3000);
}
function pendingModal() {
    $('#pendingModal').modal();
}
function hideModal() {
    $('#pendingModal').modal('hide');
}


$('.buffermodal').click(function () {
    bufferModal();
});
$('.activity-dropdown').click(function () {
    $('.ajax-dropdown').toggle('up');
    $('.activity-dropdown i').toggle();

    url = '/ajax/activities';
    container = $(".notifications");
    loadURL(url, container)

});

function imgCaract(image) {
    var img = new Image();
    img.src = image;
    img.onload = function () {
        return {
            src: image,
            width: this.width,
            height: this.height
        };
    }
    return img;
}

function errorS(text) {
    $.smallBox({
        title: 'Erreur',
        content: text,
        color: "#C46A69",
        timeout: 3000,
        icon: "fa fa-bell swing animated"
    });
}
function successS(text) {
    $.smallBox({
        title: 'Succès',
        content: text,
        color: "#10500b",
        timeout: 3000,
        icon: "fa fa-bell swing animated"
    });
}
function getSelectedOptions(sel, fn) {
    var opts = [], opt;
    // loop through options in select list
    for (var i = 0, len = sel.options.length; i < len; i++) {
        opt = sel.options[i];
        // check if selected
        if (opt.selected) {
            // add to array of option elements to return from this function
            opts.push($(opt).attr('value'));
            // invoke optional callback function if provided
            if (fn) {
                fn(opt);
            }
        }
    }
    // return array containing references to selected option elements
    return opts;
}

function resizeSuperBox() {
    // width = $('.superbox-list').width();
    // $('.superbox-list').height(width);
}
var boundx, boundy, jcrop_api;
function jcropDamien() {
// Create variables (in this scope) to hold the API and image size
// Grab some information about the preview pane
    $preview = $('#preview-pane'), $pcnt = $('#preview-pane .preview-container'), $pimg = $('#preview-pane .preview-container img'), xsize = 200, ysize = 200;

    console.log('init', [xsize, ysize]);
    jcrop_api = $.Jcrop('#new_image', {

        onChange: updatePreview,
        onSelect: updatePreview,
        onRelease: clearCoords,


        //aspectRatio: xsize / ysize
        setSelect: [200, 200, 200, 200],
        aspectRatio: 1 / 1,
        minSize: [200, 200]
    }, function (j) {
        // Use the API to get the real image size

        var bounds = this.getBounds();

        boundx = bounds[0];
        boundy = bounds[1];

        // Store the API in the jcrop_api variable
        jcrop_api = this;

        // Move the preview into the jcrop container for css positioning
        $preview.appendTo(jcrop_api.ui.holder);
    });

    $('#coords').on('change', 'input', function (e) {
        var x1 = $('#x1').val(), x2 = $('#x2').val(), y1 = $('#y1').val(), y2 = $('#y2').val();
        jcrop_api.setSelect([x1, y1, x2, y2]);
    });

    function clearCoords() {
        $('#coords input').val('');
    };
    function updatePreview(c) {
        if (parseInt(c.w) > 0) {
            var rx = xsize / c.w;
            var ry = ysize / c.h;

            $pimg.css({
                width: Math.round(rx * boundx) + 'px',
                height: Math.round(ry * boundy) + 'px',
                marginLeft: '-' + Math.round(rx * c.x) + 'px',
                marginTop: '-' + Math.round(ry * c.y) + 'px'
            });
        }
        showCoords(c);
    }

    function showCoords(c) {
        $('#x1').val(c.x);
        $('#y1').val(c.y);
        $('#x2').val(c.x2);
        $('#y2').val(c.y2);
        $('#w').val(c.w);
        $('#h').val(c.h);
    };
}

function jcropMovie(locale_id) {
    var locale_id = locale_id, xsize = 300, ysize = 169;
    console.log('init', [xsize, ysize]);
    jcrop_api = $.Jcrop('#new_image_' + locale_id, {

        onChange: showCoords,
        onSelect: showCoords,
        onRelease: clearCoords,


        aspectRatio: 16 / 9,
        setSelect: [50, 50, 350, 219],
        //aspectRatio: 1 / 1,
        minSize: [xsize, ysize]
    }, function (j) {
        // Use the API to get the real image size

        var bounds = this.getBounds();

        boundx = bounds[0];
        boundy = bounds[1];

        // Store the API in the jcrop_api variable
        jcrop_api = this;

        // Move the preview into the jcrop container for css positioning
        // $preview.appendTo(jcrop_api.ui.holder);
    });

    $('#coords').on('change', 'input', function (e) {
        var x1 = $('#x1_' + locale_id).val(), x2 = $('#x2_' + locale_id).val(), y1 = $('#y1_' + locale_id).val(), y2 = $('#y2_' + locale_id).val();
        jcrop_api.setSelect([x1, y1, x2, y2]);
    });

    function clearCoords() {
        $('#coords input_' + locale_id).val('');
    };

    function showCoords(c) {
        $('#x1_' + locale_id).val(c.x);
        $('#y1_' + locale_id).val(c.y);
        $('#x2_' + locale_id).val(c.x2);
        $('#y2_' + locale_id).val(c.y2);
        $('#w_' + locale_id).val(c.w);
        $('#h_' + locale_id).val(c.h);
    };

}

//Select Theme -> Categories
$(document).on('click', 'input[name="selectallcat"]', function (event) {  //on click

    if (this.checked) { // check select status

        $(this).parent().next().find(':checkbox').each(function () { //loop through each checkbox
            this.checked = true;  //select all checkboxes with class "checkbox1"
        });
    } else {
        $(this).parent().next().find(':checkbox').each(function () { //loop through each checkbox
            this.checked = false; //deselect all checkboxes with class "checkbox1"
        });
    }
});
//Select ALL
$(document).on('click', 'input[name="selectall"]', function (event) {  //on click
    checkbox = $(this).attr('data-checkbox');
    if (checkbox) {
        if (this.checked) { // check select status
            console.log($(this).parents('section'))
            $(this).parents('section').children().find('input[name^="' + checkbox + '"]').each(function () { //loop through each checkbox
                this.checked = true;  //select all checkboxes with class "checkbox1"
            });
        } else {
            $(this).parents('section').children().find('input[name^="' + checkbox + '"]').each(function () { //loop through each checkbox
                this.checked = false; //deselect all checkboxes with class "checkbox1"
            });
        }
    }
});

/** SEARCH ALL */
$(document).on('keyup', 'input[name="search"]', function () {
    search_all(this);
});
$('input[name="search"]').click(function () {
    search_all(this);
});
function search_all(e) {
    var value = $(e).val();
    setTimeout(function () {
        if (value.length > 2) {
            $('input[name="search"]').addClass('ajax_loader');
            $.ajax({
                type: "post",
                url: '/ajax/searchall',
                data: {value: value},
                dataType: "html",
                success: function (data, status) {
                    $('.result_search_all').show();
                    $('.search_all_main').html(data);
                },
                error: function () {
                    $('.search_all_main').html("Une erreur s'est produite. Merci de recharger la page.");
                },
                complete: function () {
                    $('input[name="search"]').removeClass('ajax_loader');
                }
            })
        }
    }, 700);
}
$(document).on('click', '.minSearch', function () {
    $('.ls_result_div').slideUp('slow');
});